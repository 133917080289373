import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {Button, Grid, Page,} from "tabler-react";


import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import SiteWrapper from "../../SiteWrapper.react";
import swal from "@sweetalert/with-react";
import {
    deleteCommerceType,
    getCommerceTypes,
    postCommerceType,
    putCommerceType
} from "../../redux/actions/commerceTypes";
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';


const options = {
    filterType: 'textfield',
    serverSide: true,
    filter:false,
    rowsPerPageOptions:[5,10,15,20,50,100]
};

function CommerceTypes(props) {
    useEffect(()=>{
        props.getCommerceTypes({limit:10, ascending:1, page:1, byColumn:0});
    },[]);


    const {Auth} = props;
    const [params, setParams] = useState({limit:10, ascending:1, page:1, byColumn:0});
    const [addModal, setAddModal] = useState(false);
    const [commerceName, setCommerceName] = useState('');
    const [idCommerceName, setIdCommerceName] = useState(null);

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Nom",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: true,
                sort: false,
                customBodyRender:(value, row)=>{
                    return <Fragment>
                        <Button className='mx-1' color="warning" icon="edit-2" onClick={()=>onEdit(row.rowData[0])}/>
                        <Button className='mx-1' color="danger" icon="trash" onClick={()=>onDelete(row.rowData[0])}/>
                    </Fragment>
                }
            }
        },
    ];


    function onEdit(id) {
        setCommerceName(props.commerceTypes.find(e=>e.id === id).name);
        setIdCommerceName(id);
        setAddModal(true);
        return undefined;
    }

    const onDelete=(id)=>{
        swal({
            type: 'error',
            title: 'Etes vous sure de vouloir supprimer ce type de commerce?',
            icon: "warning",
            buttons: ["Annuler", 'Supprimer'],
            dangerMode: true,
        }).then((value)=>{
            if(value)
                props.deleteCommerceType(id,()=>{
                    swal({
                        title: "Supprimée!",
                        icon: "success",
                    });
                    props.getCommerceTypes(params);
                });
        })

    };

    const onTableChange = (action, tableState)=>{
        let newParams=params;
        switch (action) {
            case 'search':
                newParams = {...params, page : 1, query:tableState.searchText};
                break;
            case 'changePage':
                newParams = {...params, page : tableState.page +1};
                break;
            case 'changeRowsPerPage':
                newParams = {...params, page : 1, limit:tableState.rowsPerPage};
                break;
            /*case 'rowsSelect':
                setSelectedRows( tableState.selectedRows.data.map(e => props.commerceTypes[e.dataIndex].id))
                break;
            case 'rowDelete':
                onMultipleDelete();
                break;*/
            default:
                break;
        }

        setParams(newParams);
        if(params !== newParams)
            props.getCommerceTypes(newParams);
    };

    /*const onMultipleDelete = async () => {
        await selectedRows.forEach((item) => {
            props.deleteCommerceType(item);
        });
        props.getCommerceTypes(params);

    };*/

    const onConfirmAdd = ()=>{
        if(idCommerceName!==null){
            props.putCommerceType({id:idCommerceName,name:commerceName},()=> {
                swal({
                    title: "Modifée!",
                    icon: "success",
                });
                setCommerceName('');
                setIdCommerceName(null);
                props.getCommerceTypes(params);
            });

        }
        else props.postCommerceType({name:commerceName},()=> {
            swal({
                title: "Ajoutée!",
                icon: "success",
            });
            setCommerceName('');
            props.getCommerceTypes(params);
        });

        setAddModal(false)
    };

    if(!Auth)return <Fragment/>;
    return (!Auth.loading && !Auth.isAuthenticated) ? <Redirect to="/login"/> : (
        <SiteWrapper user={Auth.user}>
            <Page.Content>
                <Grid.Row cards = {true} className='d-flex justify-content-end my-2'>
                    <Button color="info" onClick={()=>setAddModal(true)}>Ajouter un type de commerce</Button>
                </Grid.Row>
                <Grid.Row cards={true}>
                    <MUIDataTable
                        className='w-100'
                        title={"Types de commerce"}
                        data={props.commerceTypes}
                        columns={columns}
                        options={{...options,
                            count: props.count,
                            page:params.page - 1,
                            searchText:params.query,
                            selectableRows:'none',
                            rowsPerPage:params.limit,
                            onTableChange}}
                    />
                </Grid.Row>
            </Page.Content>
            <Modal isOpen={addModal} toggle={()=>setAddModal(!addModal)} >
                <ModalHeader toggle={()=>setAddModal(!addModal)} charCode="" >Ajouter/Modifier un type de commerce</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">Nom du type de commerce</Label>
                        <Input type="text" name="name" id="name" value={commerceName} onChange={(e)=>setCommerceName(e.target.value)} placeholder="Type de commerce" />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={onConfirmAdd}>Envoyer</Button>{' '}
                    <Button color="secondary" onClick={()=>setAddModal(!addModal)}>Annuler</Button>
                </ModalFooter>
            </Modal>

        </SiteWrapper>
    );
}

const mapStateToProps = (state)=>{
    return {
        Auth: state.Auth,
        commerceTypes:state.CommerceTypes.list,
        count:state.CommerceTypes.count
    }
};
export default connect(mapStateToProps,{getCommerceTypes, deleteCommerceType, postCommerceType, putCommerceType})(CommerceTypes);
