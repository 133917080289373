import * as types from '../../constants';
import {FAILURE, REQUEST, SUCCESS} from '../../ActionType';
import {AUTH_TOKEN_KEY} from "../../../config/constants";

const initialState = {
  loading: true,
  isAuthenticated: false,
  errorMessage: null,
  password_requested:false,
  password_changed:false,
  user: {},
};

const Auth = (state = initialState, action) => {
  const response = action.response;
  switch (action.type) {
    case REQUEST(types.LOGIN_USER):
      return {...state, loading:true};
    case SUCCESS(types.LOGIN_USER):
      return authLogin(state, response);
    case FAILURE(types.LOGIN_USER):
      return {...state, errorMessage: action.error.message, loading:false};
    case SUCCESS(types.GET_USER):
      return getUserSuccess(state, response);
    case REQUEST(types.GET_USER):
      return {...state, loading: true};
    case REQUEST(types.LOGOUT_USER):
      return logout(state);
    case SUCCESS(types.REQUEST_PASSWORD):
      return {...state, password_requested:true, error:false};
    case FAILURE(types.REQUEST_PASSWORD):
      return {...state, error:action.error};
    case SUCCESS(types.RESET_PASSWORD):
      return {...state, password_changed:true};
    case FAILURE(types.RESET_PASSWORD):
      return {...state, error:action.error};
    case REQUEST(types.CHANGE_PASSWORD):
      return {...state, password_changed:false,error:null};
    case SUCCESS(types.CHANGE_PASSWORD):
      return {...state, password_changed:true,error:null};
    case FAILURE(types.CHANGE_PASSWORD):
      return {...state, error:action.error,password_changed:false};
    default:
      return state;
  }
};

const getUserSuccess = (state, response) => {
  return {
    ...state,
    user: response,
    loading: false,
    isAuthenticated: true,
  };
};
const authLogin = (state, response) => {
  const jwtToken = response.token;
  localStorage.clear();
  localStorage.setItem(AUTH_TOKEN_KEY, jwtToken);
  state = Object.assign({}, state, {
    isAuthenticated: true,
    loading: false,
    errorMessage: null
  });
  return state;
};


const logout = (state) => {
  localStorage.clear();
  state = Object.assign({}, {
    isAuthenticated: false,
    user: {}
  });
  state.isAuthenticated = false;

  return state;
};


export default Auth;
