import React, {Fragment, useEffect} from "react";

import SiteWrapper from "../../SiteWrapper.react";
import {connect} from "react-redux";
import {getUser, putUser} from "../../redux/actions/users";
import {Redirect} from "react-router-dom";
import {Card, Grid, Page} from "tabler-react";
import {Formik} from 'formik';
import {Button, Input, Label} from "reactstrap";
import {getCommerceTypes} from "../../redux/actions/commerceTypes";
import cs from 'classnames';

function ShowUser(props) {

    useEffect(() => {
        props.getUser(props.match.params.id, () => {});
        props.getCommerceTypes();
    }, []);

    const {Auth} = props;
    // const [error, setError]= useState({});


    if (!Auth) return <Fragment/>;
    return (!Auth.loading && !Auth.isAuthenticated) ? <Redirect to="/login"/> :
        <SiteWrapper user={Auth.user}>
            <Page.Content>
                <Grid.Row>
                    <Card title={`Modifier l'utilisateur : ${props.user&&props.user.name}`} >
                        <Card.Body>
                            <Formik
                                initialValues={props.user}
                                enableReinitialize={true}
                                validate={values => {
                                    // same as above, but feel free to move this into a class method now.
                                    let errors = {};
                                    if (!values.email)
                                        errors.email = "Obligatoire";
                                    if (!values.name)
                                        errors.name = "Obligatoire";
                                    if (!values.username)
                                        errors.username = "Obligatoire";
                                    if (!values.phone)
                                        errors.phone = "Obligatoire";
                                    if (!values.address)
                                        errors.address = "Obligatoire";
                                    if (!values.zip_code)
                                        errors.zip_code = "Obligatoire";
                                    if (!values.commerce_type_id)
                                        errors.commerce_type_id = "Obligatoire";
                                    if (
                                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                                    ) {
                                        errors.email = "Adresse email invalide";
                                    }
                                    // setError(errors);
                                    return errors;
                                }}
                                onSubmit={(
                                    values,
                                ) => {
                                    props.putUser(values,()=>{
                                        props.history.push('/users')
                                    });
                                }}
                                render={({
                                             values,
                                             errors,
                                             touched,
                                             handleChange,
                                             handleBlur,
                                             handleSubmit,
                                         }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Grid.Row className='my-2'>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='name'>Nom complet</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.name})}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Nom complet"
                                                    valid={!errors.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                />
                                                {!!errors.name && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.name}</span>}
                                            </Grid.Col>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='username'>Nom d'utilisateur</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.username})}
                                                    type="text"
                                                    placeholder="Nom d'utilisateur"
                                                    name="username"
                                                    valid={!errors.username}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.username}
                                                />
                                                {!!errors.username && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.username}</span>}
                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row className='my-2'>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='email'>Email</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.email})}
                                                    type="email"
                                                    valid={!errors.email}
                                                    name="email"
                                                    placeholder='Email'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                {!!errors.email && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.email}</span>}
                                            </Grid.Col>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='phone'>Téléphone</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.phone})}
                                                    type="text"
                                                    valid={!errors.phone}
                                                    name="phone"
                                                    placeholder='Téléphone'
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                />
                                                {!!errors.phone && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.phone}</span>}
                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='address'>Adresse</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.address})}
                                                    type="text"
                                                    name="address"
                                                    valid={!errors.address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder='Adresse'
                                                    value={values.address}
                                                />
                                                {!!errors.address && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.address}</span>}
                                            </Grid.Col>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='zip_code'>Code postale</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.zip_code})}
                                                    type="number"
                                                    name="zip_code"
                                                    valid={!errors.zip_code}
                                                    onChange={handleChange}
                                                    placeholder='Code postale'
                                                    onBlur={handleBlur}
                                                    value={values.zip_code}
                                                />
                                                {!!errors.zip_code && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.zip_code}</span>}
                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row className='my-2'>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='commerce_type_id'>Message par défaut</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.default_message})}
                                                    type="text"
                                                    name="default_message"
                                                    valid={!errors.default_message}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder='Adresse'
                                                    value={values.default_message}
                                                />
                                                {!!errors.default_message && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.default_message}</span>}

                                            </Grid.Col>
                                            <Grid.Col lg={6} sm={12}>
                                                <Label htmlFor='commerce_type_id'>Type de commerce</Label>
                                                <Input
                                                    className={cs('form-control',{'is-invalid':!!errors.commerce_type_id})}
                                                    type="select"
                                                    name="commerce_type_id"
                                                    valid={!errors.commerce_type_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.commerce_type_id}
                                                >
                                                    {props.commerceTypes.map((e,key)=>(
                                                        <option key={key} value={e.id}>{e.name}</option>
                                                    ))}
                                                </Input>
                                                {!!errors.commerce_type_id && <span className='text-danger'><i className='fa fa-exclamation-circle'/> {errors.commerce_type_id}</span>}

                                            </Grid.Col>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Col lg={6} sm={12} className='d-flex justify-content-end align-items-end'>
                                                <Button type='submit' disabled={values === props.user} className='btn bg-blue-dark text-white' onClick={handleSubmit}>Modifier</Button>
                                            </Grid.Col>
                                        </Grid.Row>

                                    </form>
                                )}
                            />

                        </Card.Body>
                    </Card>
                </Grid.Row>
            </Page.Content>

        </SiteWrapper>;
}


const mapStateToProps = (state) => {
    return {
        Auth: state.Auth,
        user: state.Users.user,
        commerceTypes: state.CommerceTypes.list
    }
};
export default connect(mapStateToProps, {getUser, getCommerceTypes, putUser})(ShowUser);
