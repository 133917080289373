import {COUNT, DELETE, GET, LIST, PUT, REQUEST} from "../../ActionType";
import {USER_ALERTS, USER_ALERTS_PER_MONTH, USERS} from "../../constants";

export const getUsers = (params,onSuccess) => {
    return {
        type: REQUEST(LIST(USERS)),
        params,
        onSuccess,
        loading:true,
    };
};

export const getUser = (id,onSuccess) => {
    return {
        type: REQUEST(GET(USERS)),
        id,
        onSuccess,
        loading:true,
    };
};


export const putUser = (user, onSuccess) => {
    return {
        type: REQUEST(PUT(USERS)),
        user,
        onSuccess,
        loading: true,
    };
};

export const getUserAlerts = (id,params,onSuccess) => {
    return {
        type: REQUEST(GET(USER_ALERTS)),
        id,
        params,
        onSuccess,
        loading:true,
    };
};

export const getUserAlertsPerMonth = (id,params,onSuccess) => {
    return {
        type: REQUEST(GET(USER_ALERTS_PER_MONTH)),
        id,
        onSuccess,
        loading:true,
    };
};

export const deleteUser = (id,onSuccess) => {
    return {
        type: REQUEST(DELETE(USERS)),
        id,
        onSuccess,
        loading: true,
    };
};

export const countUsers = (onSuccess) => {
    return {
        type: REQUEST(COUNT(USERS)),
        onSuccess,
        loading: true,
    };
};

