import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';

import {USER_ALERTS, USER_ALERTS_PER_MONTH, USERS} from '../../constants';
import {COUNT, DELETE, FAILURE, GET, LIST, PUT, REQUEST, SUCCESS} from '../../ActionType';
import {
    countUsers,
    deleteUser,
    getUser,
    getUserAlerts,
    getUserAlertsPerMonth,
    getUsers,
    putUser
} from "../../../services/users";

export function* list(payload) {
    try {
        const response = yield call(getUsers, payload.params);
        yield put({ type: SUCCESS(LIST(USERS)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(LIST(USERS)), error });
    }
}
export function* count(payload) {
    try {
        const response = yield call(countUsers, payload.params);
        yield put({ type: SUCCESS(COUNT(USERS)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(COUNT(USERS)), error });
    }
}

export function* get(payload) {
    try {
        const response = yield call(getUser, payload.id);
        yield put({ type: SUCCESS(GET(USERS)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(GET(USERS)), error });
    }
}

export function* getAlerts(payload) {
    try {
        const response = yield call(getUserAlerts, payload.id, payload.params);
        yield put({ type: SUCCESS(GET(USER_ALERTS)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(GET(USER_ALERTS)), error });
    }
}

export function* getAlertsPerMonth(payload) {
    try {
        const response = yield call(getUserAlertsPerMonth, payload.id);
        yield put({ type: SUCCESS(GET(USER_ALERTS_PER_MONTH)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(GET(USER_ALERTS_PER_MONTH)), error });
    }
}


export function* update(payload) {
    try {
        const response = yield call(putUser, payload.user);
        yield put({ type: SUCCESS(PUT(USERS)), response });
        if(payload.onSuccess)payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(PUT(USERS)), error });
    }
}

export function* destroy(payload) {
    try {
        yield call(deleteUser, payload.id);
        yield put({ type: SUCCESS(DELETE(USERS)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(USERS)), error });
    }
}
export function* watchUsers() {
    yield takeLatest(REQUEST(LIST(USERS)), list);
    yield takeLatest(REQUEST(PUT(USERS)), update);
    yield takeLatest(REQUEST(GET(USER_ALERTS)), getAlerts);
    yield takeLatest(REQUEST(COUNT(USERS)), count);
    yield takeLatest(REQUEST(GET(USER_ALERTS_PER_MONTH)), getAlertsPerMonth);
    yield takeLatest(REQUEST(GET(USERS)), get);
    yield takeEvery(REQUEST(DELETE(USERS)), destroy);
}
