import React, {Fragment, useEffect, useState} from "react";

import SiteWrapper from "../../SiteWrapper.react";
import {connect} from "react-redux";
import {deleteUser, getUser, getUserAlerts, getUserAlertsPerMonth} from "../../redux/actions/users";
import {Redirect} from "react-router-dom";
import {Button, Card, colors, Grid, Page, Table} from "tabler-react";
import C3Chart from "react-c3js";
import {deleteAlert} from "../../redux/actions/alerts";
import MUIDataTable from "mui-datatables";
import swal from "@sweetalert/with-react";

const options = {
    filterType: 'textfield',
    serverSide: true,
    filter: false,
    download: false,
    print: false,
    selectableRows: 'none',
    rowsPerPageOptions: [5, 10, 15, 20, 50, 100]
};

function ShowUser(props) {

    const [params, setParams] = useState({limit: 10, ascending: 1, page: 1, byColumn: 0});

    useEffect(() => {
        props.getUser(props.match.params.id, () => {});
        props.getUserAlerts(props.match.params.id, params, ()=>{});
        props.getUserAlertsPerMonth(props.match.params.id, ()=>{});
    }, []);

    const {Auth} = props;

    const onTableChange = (action, tableState) => {
        let newParams = params;
        switch (action) {
            case 'search':
                newParams = {...params, page: 1, query: tableState.searchText};
                break;
            case 'changePage':
                newParams = {...params, page: tableState.page + 1};
                break;
            case 'changeRowsPerPage':
                newParams = {...params, page: 1, limit: tableState.rowsPerPage};
                break;
            default:
                break;
        }
        setParams(newParams);
        if (params !== newParams)
            props.getUserAlerts(props.match.params.id, newParams, ()=>{});
    };

    const onDelete = (id) => {
        swal({
            type: 'error',
            title: 'Etes vous sure de vouloir supprimer cette alerte?',
            icon: "warning",
            buttons: ["Annuler", 'Supprimer'],
            dangerMode: true,
        }).then((value) => {
            if (value)
                props.deleteAlert(id, () => {
                    swal({
                        title: "Supprimee!",
                        icon: "success",
                    });
                    props.getAlerts(params);
                });
        })

    };

    const columns = [
        {
            name: "id",
            label: "",
            options: {
                filter: true,
                sort: false,
                display: 'excluded'
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "message",
            label: "Message",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return <a href={'https://www.google.com/maps?q=' + value} rel="noopener noreferrer" target='_blank'>Voir
                        Localisation</a> || 'Aucune geolocalisation n\'est enregistre'
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, row) => {
                    return <Button color="danger" icon="trash" onClick={() => onDelete(row.rowData[0])}/>
                }
            }
        },
        {
            name: "id",
            label: "Sécurité?",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, row) => {
                    return row.rowData[2].includes("Je suis en sécurité") ?
                        <i className="fa fa-check text-green fa-2x"/>
                        : <i className="fa fa-exclamation fa-2x text-red"/>
                }
            }
        },
    ];


    if (!Auth) return <Fragment/>;
    return (!Auth.loading && !Auth.isAuthenticated) ? <Redirect to="/login"/> :
        <SiteWrapper user={Auth.user}>
            <Page.Content>
                <Grid.Row className='d-flex justify-content-end'>
                    <Button className="btn btn-lg btn-warning m-3"
                            onClick={()=>props.history.push(`/users/${props.match.params.id}/edit`)}>
                        Modifier l'utilisateur
                    </Button>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Alertes annuels de l'utilisateur</Card.Title>
                            </Card.Header>
                            <C3Chart
                                style={{height: "10rem"}}
                                data={{
                                    columns: [
                                        // each columns data
                                        [
                                            "data1",
                                            ...Object.values(props.alertsPerMonth)
                                        ],
                                    ],
                                    type: "area", // default type of chart
                                    groups: [["data1", "data2", "data3"]],
                                    colors: {
                                        data1: colors["red"],
                                    },
                                    names: {
                                        // name of each serie
                                        data1: "Alertes",
                                    },
                                }}
                                axis={{
                                    y: {
                                        padding: {
                                            bottom: 0,
                                        },
                                        show: false,
                                        tick: {
                                            outer: false,
                                        },
                                    },
                                    x: {
                                        padding: {
                                            left: 0,
                                            right: 0,
                                        },
                                        show: false,
                                    },
                                }}
                                legend={{
                                    position: "inset",
                                    padding: 0,
                                    inset: {
                                        anchor: "top-left",
                                        x: 20,
                                        y: 8,
                                        step: 10,
                                    },
                                }}
                                tooltip={{
                                    format: {
                                        title: function (x) {
                                            return x+"";
                                        },
                                    },
                                }}
                                padding={{
                                    bottom: 0,
                                    left: -1,
                                    right: -1,
                                }}
                                point={{
                                    show: false,
                                }}
                            />
                        </Card>

                        {props.user && <Grid.Row>
                            <Grid.Col sm={6} lg={6}>
                                <Card title="Informations personnels de l'utilisateur">
                                    <Table cards>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Col>Nom complet</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.title + '. ' + props.user.name}
                                                </Table.Col>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Col>Nom d'utilisateur</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.username}
                                                </Table.Col>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Col>Type de commerce</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.commerce_type && props.user.commerce_type.name}
                                                </Table.Col>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Col>Statut</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.is_valid? 'Active':'Desactive'}
                                                </Table.Col>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Card>
                            </Grid.Col>
                            <Grid.Col sm={6} lg={6}>
                                <Card title="Coordonnées de l'utilisateur">
                                    <Table cards>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Col>Adresse</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.address + ', '+props.user.zip_code}
                                                </Table.Col>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Col>Email</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.email}
                                                </Table.Col>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Col>Téléphone</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.phone}
                                                </Table.Col>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Col>Derniere location</Table.Col>
                                                <Table.Col alignContent="right">
                                                    {props.user.last_location?<a href={'https://www.google.com/maps?q='+props.user.last_location} rel="noopener noreferrer" target='_blank'>Voir Localisation</a> : 'Aucune geolocalisation n\'est enregistre'}
                                                </Table.Col>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Card>
                            </Grid.Col>
                            <Grid.Col sm={12}>
                                <Card title="Message par défaut de l'utilisateur">
                                    <Table cards>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Col>{props.user.default_message}</Table.Col>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Card>
                            </Grid.Col>
                        </Grid.Row>}

                        <Card>

                            <MUIDataTable
                                className='w-100'
                                title={"Alertes"}
                                data={props.alerts}
                                columns={columns}
                                options={{
                                    ...options,
                                    count: props.count,
                                    page: params.page - 1,
                                    searchText: params.query,
                                    rowsPerPage: params.limit,
                                    onTableChange
                                }}
                            />

                        </Card>
                    </Grid.Col>

                </Grid.Row>
            </Page.Content>

        </SiteWrapper>;
}


const mapStateToProps = (state) => {
    return {
        Auth: state.Auth,
        user: state.Users.user,
        alerts: state.Users.alerts,
        alertsPerMonth:state.Users.alertsPerMonth,
        count: state.Users.countAlerts
    }
};
export default connect(mapStateToProps, {getUser, getUserAlertsPerMonth , deleteAlert, getUserAlerts, deleteUser})(ShowUser);
