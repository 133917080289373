import axios from "../../config/axios"
import {LOGIN_ENDPOINT, USER_ENDPOINT} from "../endpoints";

export const login = (user) => {
  return axios.post(LOGIN_ENDPOINT, user)
    .then(response => response.data);
};

export const getUser = () => {
  return axios.get(USER_ENDPOINT)
    .then(response => response.data);
};
