
export const sortTemplate = (
    columns,
    {activeColumn, announceText, searchText} = {announceText: 'desc'}
) => {
    announceText = announceText && announceText.includes('ascending')
        ? 'asc'
        : 'desc';

    activeColumn = activeColumn || activeColumn === 0 ? activeColumn : 'none';

    return `${searchText ? '' : activeColumn === 'none' ? 'id' : columns[activeColumn].name},${announceText}`;
};


export const deleteSuccess=(state, id) =>{
    const list=state.list.filter(e=>e.id!==id);
    state = Object.assign({},state,{list,count:state.count-1});
    return state;
};
