import axios from "../../config/axios";
import {COMMERCE_TYPES_ENDPOINT} from "../endpoints";

export const getCommerceTypes = (params) => {
    return axios.get(COMMERCE_TYPES_ENDPOINT,{params:params || {limit:10, ascending:1, page:1, byColumn:0}})
        .then(response => {
            return {list:response.data.data, count: response.data.count }
        });
};


export const getCommerceType = (id) => {
    return axios.get(`${COMMERCE_TYPES_ENDPOINT}/${id}`)
        .then(response => ({commerceType: response.data}));
};


export const postCommerceType = (commerceType) => {
    return axios.post(COMMERCE_TYPES_ENDPOINT, commerceType)
        .then(response => response.data);

};
export const putCommerceType = (commerceType) => {
    return axios.put(`${COMMERCE_TYPES_ENDPOINT}/${commerceType.id}`, commerceType)
        .then(response => response.data);

};

export const deleteCommerceType = (id) => {
    return axios.delete(`${COMMERCE_TYPES_ENDPOINT}/${id}`)
        .then(response => response.data);
};

export const countCommerceTypes = () => {
    return axios.get(`${COMMERCE_TYPES_ENDPOINT}/count`)
        .then(response => response.data);
};
