import {GET_USER, LOGIN_USER, LOGOUT_USER, REQUEST_PASSWORD, CHANGE_PASSWORD, RESET_PASSWORD} from '../../constants';
import {REQUEST} from '../../ActionType';

export const loginUserAction = (user,onSuccess, onError) => {
    return {
        type: REQUEST(LOGIN_USER),
        user,
        onSuccess,
        loading:true,
        onError
    };
};

export const passwordChange = (request) => {
    return {
        type: REQUEST(CHANGE_PASSWORD),
        request,
        loading:true,
    };
};
export const passwordReset = (request) => {
    return {
        type: REQUEST(RESET_PASSWORD),
        request,
        loading:true,
    };
};

export const requestPassword = (email) => {
    return {
        type: REQUEST(REQUEST_PASSWORD),
        email,
        loading:true,
    };
};



export const getCurrentUser = (token) => {
    return {
        type: REQUEST(GET_USER),
        token,
    };
};

export const logout= () => {
    return {
        type: REQUEST(LOGOUT_USER)
    };
};
