import axios from "../../config/axios";
import {ALERTS_ENDPOINT, GET_ALERT_ENDPOINT} from "../endpoints";

export const getAlerts = (params) => {
    return axios.get(ALERTS_ENDPOINT,{params:params || {limit:10, ascending:1, page:1, byColumn:0}})
        .then(response => {
            return {list:response.data.data, count: response.data.count }
        });
};

export const getAlert = (id) => {
    return axios.get(`${GET_ALERT_ENDPOINT}/${id}`)
        .then(response => ({alert: response.data}));
};

export const deleteAlert = (id) => {
    return axios.delete(`${GET_ALERT_ENDPOINT}/${id}`)
        .then(response => response.data);
};
export const countAlerts = () => {
    return axios.get(`${GET_ALERT_ENDPOINT}/count`)
        .then(response => response.data);
};

