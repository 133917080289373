import axios from 'axios';
import {AUTH_TOKEN_KEY, SERVER_URL} from "./constants";
import swal from "@sweetalert/with-react";

const instance = axios.create({
  baseURL: SERVER_URL,
});

instance.interceptors.request.use(async request => {
  const token = await localStorage.getItem(AUTH_TOKEN_KEY);
  if (token) {
    request.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return request;
});

// Response interceptor
instance.interceptors.response.use(response => response, error => {
  const { status } = error.response;
  if (status >= 500) {
    swal({
      type: 'error',
      title: ('error_alert_title'),
      text: ('error_alert_text'),
      reverseButtons: true,
      confirmButtonText: ('ok'),
      cancelButtonText: ('cancel')
    })
  }

  if (status === 400 && window.location.href.includes("/login") && error.response.data.error.includes("Already authenticated.")) {
      window.location.href= "/"
  }
  if (status === 401 && !window.location.href.includes("/login")) {
      window.location.href= "/login"
  }

  return Promise.reject(error)
});

export default instance;
