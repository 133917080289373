import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';

import {ALERTS} from '../../constants';
import {COUNT, DELETE, FAILURE, GET, LIST, REQUEST, SUCCESS} from '../../ActionType';
import {countAlerts, deleteAlert, getAlert, getAlerts} from "../../../services/alerts";

export function* list(payload) {
    try {
        const response = yield call(getAlerts, payload.params);
        yield put({ type: SUCCESS(LIST(ALERTS)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(LIST(ALERTS)), error });
    }
}
export function* count(payload) {
    try {
        const response = yield call(countAlerts, payload.params);
        yield put({ type: SUCCESS(COUNT(ALERTS)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(COUNT(ALERTS)), error });
    }
}

export function* get(payload) {
    try {
        const response = yield call(getAlert, payload.id);
        yield put({ type: SUCCESS(GET(ALERTS)), response });
        if(payload.onSuccess)payload.onSuccess(response.alert);
    } catch(error) {
        yield put({ type: FAILURE(GET(ALERTS)), error });
    }
}

export function* destroy(payload) {
    try {
        yield call(deleteAlert, payload.id);
        yield put({ type: SUCCESS(DELETE(ALERTS)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(ALERTS)), error });
    }
}
export function* watchAlerts() {
    yield takeLatest(REQUEST(LIST(ALERTS)), list);
    yield takeLatest(REQUEST(GET(ALERTS)), get);
    yield takeLatest(REQUEST(COUNT(ALERTS)), count);
    yield takeEvery(REQUEST(DELETE(ALERTS)), destroy);
}
