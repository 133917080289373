// @flow

import * as React from "react";
import {connect} from "react-redux";
import {Formik} from "formik";
import {Card, Grid, Page} from "tabler-react";
import {loginUserAction} from "../../redux/actions/auth"
import {Alert, Button, Input, Label} from "reactstrap";
import cs from "classnames";
import logo from "../../assets/images/logo.png"
import {useState} from "react";

type Props = {||};

function LoginPage(props: Props): React.Node {
    const [errorMessage, setErrorMessage] = useState('');
    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
            }}
            validate={values => {
                // same as above, but feel free to move this into a class method now.
                let errors = {};
                if (!values.email) {
                    errors.email = "Obligatoire";
                } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                ) {
                    errors.email = "Adresse email invalide";
                }
                return errors;
            }}
            onSubmit={(
                values,
            ) => {
                props.loginUserAction(values, () => {
                    props.history.push("/");
                    setErrorMessage('');
                }, (error)=>{
                    if(error.status===422)
                        setErrorMessage("L'adresse e-mail ou le mot de passe que vous avez entré n'est pas valide.");
                })
            }}
            render={({
                         values,
                         errors,
                         touched,
                         handleChange,
                         handleBlur,
                         handleSubmit,
                     }) => (
                    <Page.Content className='d-flex h-100 m-0 background-container'>
                        <Grid.Row className='d-flex my-4 mx-1 justify-content-center align-content-center'>
                            <Grid.Col md={6} sm={8} xs={12} className='my-4'>
                                <Card className='p-0 p-md-4 p-lg-4 p-xl-4'>
                                    <Card.Header className='d-flex justify-content-center'>
                                        <img src={logo} width='50%' alt="MyCityZ'"/>
                                    </Card.Header>
                                    <Card.Body>
                                        <form onSubmit={handleSubmit}>
                                            <Grid.Row className='my-2'>
                                                <Grid.Col md={12} className='my-2'>
                                                    <Label htmlFor='email'>Email</Label>
                                                    <Input
                                                        className={cs('form-control', {'is-invalid': !!errors.email})}
                                                        type="email"
                                                        valid={!errors.email}
                                                        name="email"
                                                        placeholder='Email'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                    />
                                                    {!!errors.email && <span className='text-danger'><i
                                                        className='fa fa-exclamation-circle'/> {errors.email}</span>}
                                                </Grid.Col>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Col md={12} className='my-2'>
                                                    <Label htmlFor='phone'>Mot de passe</Label>
                                                    <Input
                                                        className={cs('form-control', {'is-invalid': !!errors.phone})}
                                                        type="password"
                                                        valid={!errors.password}
                                                        name="password"
                                                        placeholder='Mot de passe'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.password}
                                                    />
                                                    {!!errors.password && <span className='text-danger'><i
                                                        className='fa fa-exclamation-circle'/> {errors.password}</span>}
                                                </Grid.Col>
                                            </Grid.Row>
                                            {errorMessage!==''&&<Grid.Row className='my-2 m-1'>
                                                <Alert color="danger">
                                                    {errorMessage}
                                                </Alert>
                                            </Grid.Row>}
                                            <Grid.Row className='my-2'>
                                                <Grid.Col sm={12} className='d-flex justify-content-center align-items-center'>
                                                    <Button type='submit' disabled={values === props.user}
                                                            className='btn bg-blue-dark btn-lg text-white'
                                                            onClick={handleSubmit}>Se connecter</Button>
                                                </Grid.Col>
                                            </Grid.Row>

                                        </form>
                                    </Card.Body>
                                </Card>

                            </Grid.Col>
                        </Grid.Row>
                    </Page.Content>
            )}
        />
    );
}

const mapStateToProps = (state) => {
    return {
        Auth: state.Auth
    }
};
export default connect(mapStateToProps, {loginUserAction})(LoginPage);
