import * as React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Error404, Error500, ForgotPasswordPage, LoginPage, } from "./pages";
import {connect} from "react-redux";
import HomePage from "./HomePage.react";
import Alerts from "./pages/alerts/Alert.react";
import CommerceTypes from "./pages/commerceTypes/CommerceType.react";
import Users from "./pages/users/User.react";
import ShowUser from "./pages/users/Show.react";
import EditUser from "./pages/users/Edit.react";

import "tabler-react/dist/Tabler.css";
import {AUTH_TOKEN_KEY} from "./config/constants";
import {getCurrentUser} from "./redux/actions/auth";
import './assets/style/Style.scss'
function App(props) {
  React.useEffect(()=>{
    async function fetchUser(){
      const token = await localStorage.getItem(AUTH_TOKEN_KEY);
      props.getCurrentUser(token);
    }
    fetchUser();
  }, []);

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/" component={HomePage}  />
          <Route exact path="/alerts" component={Alerts} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/:id" component={ShowUser} />
          <Route exact path="/users/:id/edit" component={EditUser} />
          <Route exact path="/commerce-types" component={CommerceTypes} />
          <Route exact path="/forgot-password" component={ForgotPasswordPage} />
          <Route exact path="/500" component={Error500} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

const mapStateToProps = (state)=>{
  return {
    Auth: state.Auth
  }
};
export default connect(mapStateToProps, {getCurrentUser})(App);
