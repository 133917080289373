import {COUNT, DELETE, FAILURE, GET, LIST, PUT, REQUEST, SUCCESS} from "../../ActionType";
import {USER_ALERTS, USER_ALERTS_PER_MONTH, USERS} from "../../constants";
import {deleteSuccess} from "../../../utils";


const initialState = {
    list: [],
    user:{},
    alerts:[],
    alertsPerMonth:{},
    errorMessage: null,
    loading: false,
    count:0,
    countAlerts:0
};

const Users = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(USERS)):
            return {...state, loading:true};
        case SUCCESS(LIST(USERS)):
            return {...state, list:response.list, count: response.count, loading:false};
        case FAILURE(LIST(USERS)):
            return {...state, list:[], errorMessage: action.error.message, loading:false};

        case REQUEST(COUNT(USERS)):
            return {...state, loading:true};
        case SUCCESS(COUNT(USERS)):
            return {...state, count:response, loading:false};
        case FAILURE(COUNT(USERS)):
            return {...state, count: 0, errorMessage: action.error.message, loading:false};

        case REQUEST(GET(USER_ALERTS)):
            return {...state, loading:true};
        case SUCCESS(GET(USER_ALERTS)):
            return {...state, alerts:response.alerts, countAlerts:response.countAlerts, loading:false};
        case FAILURE(GET(USER_ALERTS)):
            return {...state, alerts:[], errorMessage: action.error.message, loading:false};

        case REQUEST(GET(USER_ALERTS_PER_MONTH)):
            return {...state, loading:true};
        case SUCCESS(GET(USER_ALERTS_PER_MONTH)):
            return {...state, alertsPerMonth:response.alertsPerMonth, loading:false};
        case FAILURE(GET(USER_ALERTS_PER_MONTH)):
            return {...state, alertsPerMonth:[], errorMessage: action.error.message, loading:false};

        case REQUEST(GET(USERS)):
            return {...state, loading:true};
        case SUCCESS(GET(USERS)):
            return {...state, user:response.user, loading:false};
        case FAILURE(GET(USERS)):
            return {...state, user:{}, errorMessage: action.error.message, loading:false};

        case REQUEST(PUT(USERS)):
            return {...state, loading: true};
        case SUCCESS(PUT(USERS)):
            return {...state, loading: false, user: {}};
        case FAILURE(PUT(USERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(DELETE(USERS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(USERS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(USERS)):
            return {...state, errorMessage: action.error.message, loading: false};

        default:
            return state;
    }
};
export default Users;
