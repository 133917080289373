import axios from "../../config/axios";
import {USERS_ENDPOINT} from "../endpoints";

export const getUsers = (params) => {
    return axios.get(USERS_ENDPOINT,{params:params || {limit:10, ascending:1, page:1, byColumn:0}})
        .then(response => {
            return {list:response.data.data, count: response.data.count }
        });
};

export const getUser = (id) => {
    return axios.get(`${USERS_ENDPOINT}/${id}`)
        .then(response => ({user: response.data}));
};

export const getUserAlerts = (id,params) => {
    return axios.get(`${USERS_ENDPOINT}/${id}/alerts`,{params:params})
        .then(response => ({alerts: response.data.data, countAlerts:response.data.count}));
};
export const getUserAlertsPerMonth = (id) => {
    return axios.get(`${USERS_ENDPOINT}/${id}/alerts_per_month`)
        .then(response => ({alertsPerMonth: response.data}));
};


export const putUser = (user) => {
    return axios.put(`${USERS_ENDPOINT}/${user.id}`, user)
        .then(response => response.data);

};

export const deleteUser = (id) => {
    return axios.delete(`${USERS_ENDPOINT}/${id}`)
        .then(response => response.data);
};
export const countUsers = () => {
    return axios.get(`${USERS_ENDPOINT}/count`)
        .then(response => response.data);
};
