import {COUNT, DELETE, GET, LIST, REQUEST} from "../../ActionType";
import {ALERTS} from "../../constants";

export const getAlerts = (params,onSuccess) => {
    return {
        type: REQUEST(LIST(ALERTS)),
        params,
        onSuccess,
        loading:true,
    };
};

export const getAlert = (id,onSuccess) => {
    return {
        type: REQUEST(GET(ALERTS)),
        id,
        onSuccess,
        loading:true,
    };
};


export const deleteAlert = (id,onSuccess) => {
    return {
        type: REQUEST(DELETE(ALERTS)),
        id,
        onSuccess,
        loading: true,
    };
};

export const countAlerts = (onSuccess) => {
    return {
        type: REQUEST(COUNT(ALERTS)),
        onSuccess,
        loading: true,
    };
};


