import {COUNT, DELETE, FAILURE, GET, LIST, REQUEST, SUCCESS} from "../../ActionType";
import {ALERTS} from "../../constants";
import {deleteSuccess} from "../../../utils";


const initialState = {
    list: [],
    errorMessage: null,
    loading: false,
    alert:{},
    count:0
};

const Alerts = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(ALERTS)):
            return {...state, loading:true};
        case SUCCESS(LIST(ALERTS)):
            return {...state, list:response.list, count: response.count, loading:false};
        case FAILURE(LIST(ALERTS)):
            return {...state, list:[], errorMessage: action.error.message, loading:false};

        case REQUEST(GET(ALERTS)):
            return {...state, loading:true};
        case SUCCESS(GET(ALERTS)):
            return {...state, alert:response.alert, loading:false};
        case FAILURE(GET(ALERTS)):
            return {...state, alert:{}, errorMessage: action.error.message, loading:false};

        case REQUEST(COUNT(ALERTS)):
            return {...state, loading:true};
        case SUCCESS(COUNT(ALERTS)):
            return {...state, count:response, loading:false};
        case FAILURE(COUNT(ALERTS)):
            return {...state, count:0, errorMessage: action.error.message, loading:false};

        case REQUEST(DELETE(ALERTS)):
            return {...state, loading: true};
        case SUCCESS(DELETE(ALERTS)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(ALERTS)):
            return {...state, errorMessage: action.error.message, loading: false};


        default:
            return state;
    }
};
export default Alerts;
