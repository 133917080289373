// @flow

import React from "react";
import ReactDOM from "react-dom";

import {Provider} from 'react-redux'
import store from './redux'

import "./index.css";
import "./c3jscustom.css";

import App from "./App.react";
import Echo from "laravel-echo"
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const rootElement = document.getElementById("root");
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '43420bc822afce974fb4',
    cluster: 'eu',
    forceTLS: true,
    authEndpoint: 'https://api.mycityz.fr/broadcasting/auth',
});

if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
        <ReactNotification />
        <App/>
    </Provider>,
    rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
