import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';

import {COMMERCE_TYPES} from '../../constants';
import {COUNT, DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from '../../ActionType';
import {
    countCommerceTypes,
    deleteCommerceType,
    getCommerceTypes,
    postCommerceType,
    putCommerceType
} from "../../../services/commerceTypes";

export function* list(payload) {
    try {
        const response = yield call(getCommerceTypes, payload.params);
        yield put({ type: SUCCESS(LIST(COMMERCE_TYPES)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(LIST(COMMERCE_TYPES)), error });
    }
}
export function* count(payload) {
    try {
        const response = yield call(countCommerceTypes, payload.params);
        yield put({ type: SUCCESS(COUNT(COMMERCE_TYPES)), response });
        if(payload.onSuccess) payload.onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(COUNT(COMMERCE_TYPES)), error });
    }
}


export function* get(payload) {
    try {
        const response = yield call(getCommerceTypes, payload.id);
        yield put({ type: SUCCESS(GET(COMMERCE_TYPES)), response });
    } catch(error) {
        yield put({ type: FAILURE(GET(COMMERCE_TYPES)), error });
    }
}


export function* post(payload) {
    try {
        const response = yield call(postCommerceType, payload.commerceType);
        yield put({ type: SUCCESS(POST(COMMERCE_TYPES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(POST(COMMERCE_TYPES)), error });
    }
}

export function* update(payload) {
    try {
        const response = yield call(putCommerceType, payload.commerceType);
        yield put({ type: SUCCESS(PUT(COMMERCE_TYPES)), response });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(PUT(COMMERCE_TYPES)), error });
    }
}

export function* destroy(payload) {
    try {
        yield call(deleteCommerceType, payload.id);
        yield put({ type: SUCCESS(DELETE(COMMERCE_TYPES)), response:payload.id });
        if(payload.onSuccess) payload.onSuccess();

    } catch(error) {
        yield put({ type: FAILURE(DELETE(COMMERCE_TYPES)), error });
    }
}
export function* watchCommerceTypes() {
    yield takeLatest(REQUEST(LIST(COMMERCE_TYPES)), list);
    yield takeLatest(REQUEST(GET(COMMERCE_TYPES)), get);
    yield takeLatest(REQUEST(COUNT(COMMERCE_TYPES)), count);
    yield takeLatest(REQUEST(POST(COMMERCE_TYPES)), post);
    yield takeLatest(REQUEST(PUT(COMMERCE_TYPES)), update);
    yield takeEvery(REQUEST(DELETE(COMMERCE_TYPES)), destroy);
}
