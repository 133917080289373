// @flow

import * as React from "react";
import cn from "classnames";

import "./GoogleMap.css";

import {
  withScriptjs,
  withGoogleMap,
    Marker,
  GoogleMap as ReactGoogleMap,
} from "react-google-maps";

const MapComponent: React.ElementType = withScriptjs(
  withGoogleMap(props => {
    return (
        <ReactGoogleMap
            defaultZoom={16}
            defaultCenter={props}
        >
          <Marker position={props} />
        </ReactGoogleMap>
    )
  })
);

type Props = {|
  +blackAndWhite?: boolean,
|};

function GoogleMap({ blackAndWhite, location }: Props): React.Node {
  const containerClasses = cn("GoogleMapContainer", { blackAndWhite });
  return (
    <MapComponent
      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDlF6Oeq2blDgb5Z_YMN6O3BSu4OyWtfBE"
      loadingElement={<div style={{ height: `100%` }} />}
      lat={parseFloat(location.split(',')[0])}
      lng={parseFloat(location.split(',')[1])}
      containerElement={<div className={containerClasses} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
}

export default GoogleMap;
