import * as React from "react";
import {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {Button, Grid, Page,} from "tabler-react";
import {connect} from "react-redux";
import SiteWrapper from "../../SiteWrapper.react";
import swal from "@sweetalert/with-react";
import axios from '../../config/axios'
import {deleteUser, getUsers} from "../../redux/actions/users";


const options = {
    filterType: 'textfield',
    serverSide: true,
    filter:false,
    selectableRows:'none',
    rowsPerPageOptions:[5,10,15,20,50,100]
};

function Users(props) {
    useEffect(()=>{
        props.getUsers({limit:10, ascending:1, page:1, byColumn:0});
    },[]);

    function onGeolocation(id) {
        swal({
            title: 'Demander geolocalisation',
            text: 'Envoyer un message avec votre demande de geolocalisation:',
            content: "input",
            buttons: ['Annuler','Envoyer']
        }).then((result) => {
            axios.post('/users/' + id + '/request_location', {message: result}).then(() => {
                props.getUsers(params);
            })
        })
    }

    function onPreview(id) {
        props.history.push('/users/'+id);
        return undefined;
    }

    function toggleActive(id) {
        axios.put('/users/' + id + '/validate').then(() => {
            props.getUsers(params,()=>{});
        })
    }

    function onEdit(id) {
        props.history.push(`/users/${id}/edit`);
        return undefined;
    }

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "title",
            label: "Civilité",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "name",
            label: "Nom Complet",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "zip_code",
            label: "ZipCode",
            options: {
                display:'excluded',
                filter: false,
                sort: false,
            }
        },
        {
            name: "address",
            label: "Adresse",
            options: {
                filter: true,
                sort: false,
                customBodyRender:(value,row)=>{
                    return value + ', ' + row.rowData[5]
                }
            }
        },
        {
            name: "commerce_type",
            label: "Commerce",
            options: {
                filter: true,
                sort: false,
                customBodyRender:(value)=>{
                    return value.name
                }
            }
        },
        {
            name: "last_location",
            label: "Derniere localisation",
            options: {
                filter: true,
                sort: false,
                customBodyRender:(value)=>{
                    return <a href={'https://www.google.com/maps?q='+value} rel="noopener noreferrer" target='_blank'>Voir Localisation</a> || 'Aucune geolocalisation n\'est enregistre'
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: true,
                sort: false,
                customBodyRender:(value, row)=>{
                    return <div style={{minWidth:'230px'}}>
                        <Button className='mx-1' color="primary" icon="map-pin" onClick={()=>onGeolocation(row.rowData[0])}/>
                        <Button className='mx-1' color="info" icon="eye" onClick={()=>onPreview(row.rowData[0])}/>
                        <Button className='mx-1' color="warning" icon="edit-2" onClick={()=>onEdit(row.rowData[0])}/>
                        <Button className='mx-1' color={row.rowData[10] ===1 ?"dark-gray":"success"} icon={row.rowData[10] ===1 ?"slash":"check"} onClick={()=>toggleActive(row.rowData[0])}/>
                        <Button className='mx-1' color="danger" icon="trash" onClick={()=>onDelete(row.rowData[0])}/>
                    </div>
                }
            }
        },
        {
            name: "is_valid",
            label: "isValid",
            options: {
                filter: false,
                display:"excluded",
                sort: false,
            }
        }
    ];

    const onDelete=(id)=>{
        swal({
            type: 'error',
            title: 'Etes vous sure de vouloir supprimer cet utilisateur?',
            icon: "warning",
            buttons: ["Annuler", 'Supprimer'],
            dangerMode: true,
        }).then((value)=>{
            if(value)
                props.deleteUser(id,()=>{
                    swal({
                        title: "Supprimee!",
                        icon: "success",
                    });
                    props.getUsers(params);
                });
        })

    };

    const [params, setParams] = useState({limit:10, ascending:1, page:1, byColumn:0});
    const {Auth} = props;

    const onTableChange = (action, tableState)=>{
        let newParams=params;
        switch (action) {
            case 'search':
                newParams = {...params, page : 1, query:tableState.searchText};
                break;
            case 'changePage':
                newParams = {...params, page : tableState.page +1};
                break;
            case 'changeRowsPerPage':
                newParams = {...params, page : 1, limit:tableState.rowsPerPage};
                break;
            default:
                break;
        }
        setParams(newParams);
        if(params !== newParams)
            props.getUsers(newParams);
    };
    return (
        <SiteWrapper user={Auth.user}>
            <Page.Content>
                <Grid.Row cards={true}>
                    <MUIDataTable
                        className='w-100'
                        title={"Utilisateurs"}
                        data={props.users}
                        columns={columns}
                        options={{...options, count: props.count, page:params.page - 1, searchText:params.query, rowsPerPage:params.limit, onTableChange}}
                    />
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    );
}

const mapStateToProps = (state)=>{
    return {
        Auth: state.Auth,
        users:state.Users.list,
        count:state.Users.count
    }
};
export default connect(mapStateToProps,{getUsers, deleteUser})(Users);
