import {COUNT, DELETE, FAILURE, GET, LIST, POST, PUT, REQUEST, SUCCESS} from "../../ActionType";
import {COMMERCE_TYPES} from "../../constants";
import {deleteSuccess} from "../../../utils";


const initialState = {
    list: [],
    commerceType:{},
    errorMessage: null,
    loading: false,
    count:0
};

const CommerceTypes = (state = initialState, action) => {
    const response = action.response;
    switch (action.type) {
        case REQUEST(LIST(COMMERCE_TYPES)):
            return {...state, loading:true};
        case SUCCESS(LIST(COMMERCE_TYPES)):
            return {...state, list:response.list, count: response.count, loading:false};
        case FAILURE(LIST(COMMERCE_TYPES)):
            return {...state, list:[], errorMessage: action.error.message, loading:false};

        case REQUEST(GET(COMMERCE_TYPES)):
            return {...state, loading: true, commerceType:{}};
        case SUCCESS(GET(COMMERCE_TYPES)):
            return {...state, loading: false, commerceType: response.commerceType};
        case FAILURE(GET(COMMERCE_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};

        case REQUEST(COUNT(COMMERCE_TYPES)):
            return {...state, loading: true};
        case SUCCESS(COUNT(COMMERCE_TYPES)):
            return {...state, loading: false, count: response.count};
        case FAILURE(COUNT(COMMERCE_TYPES)):
            return {...state, errorMessage: action.error.message, count:0, loading: false};

        case REQUEST(POST(COMMERCE_TYPES)):
            return {...state, loading: true};
        case SUCCESS(POST(COMMERCE_TYPES)):
            return {...state, loading: false, commerceType: {}};
        case FAILURE(POST(COMMERCE_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};


        case REQUEST(PUT(COMMERCE_TYPES)):
            return {...state, loading: true};
        case SUCCESS(PUT(COMMERCE_TYPES)):
            return {...state, loading: false, commerceType: {}};
        case FAILURE(PUT(COMMERCE_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};


        case REQUEST(DELETE(COMMERCE_TYPES)):
            return {...state, loading: true};
        case SUCCESS(DELETE(COMMERCE_TYPES)):
            return deleteSuccess(state,response);
        case FAILURE(DELETE(COMMERCE_TYPES)):
            return {...state, errorMessage: action.error.message, loading: false};


        default:
            return state;
    }
};
export default CommerceTypes;
