export const REQUEST_PASSWORD = 'REQUEST_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LOGIN_USER = 'LOGIN_USER';
export const GET_USER = 'GET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const ALERTS = 'ALERTS';
export const COMMERCE_TYPES = 'COMMERCE_TYPES';
export const USERS = 'USERS';
export const USER_ALERTS = 'USER_ALERTS';
export const USER_ALERTS_PER_MONTH = 'USER_ALERTS_PER_MONTH';

