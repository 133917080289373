// @flow

import * as React from "react";
import {NavLink, withRouter} from "react-router-dom";

import {RouterContextProvider, Site,} from "tabler-react";


type Props = {|
  +children: React.Node,
|};


type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
  +useExact?: boolean,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = [
  {
    value: "Accueil",
    to: "/",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Alertes",
    icon: "bell",
    to: "/alerts",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Utilisateurs",
    icon: "user",
    to: "/users",
    LinkComponent: withRouter(NavLink),
  },
  {
    value: "Types de commerce",
    icon: "target",
    to: "/commerce-types",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
];



class SiteWrapper extends React.Component<Props, State> {

  render(): React.Node {

    const {user} = this.props;


    const accountDropdownProps = {
      avatarURL: user&&user.image?"data:image/jpeg,base64:"+user.image:"./demo/faces/female/25.jpg",
      name: user&&user.name,
      description: `@${user&&user.username}`,
      options: [
        { icon: "log-out", value: "Se déconnecter", onClick:async ()=>{
          await localStorage.clear();
          window.location.href = "/login";
          }},
      ],
    };
    return (
      <Site.Wrapper
        headerProps={{
          href: "/",
          alt: "myCityZ'",
          imageURL: "/assets/images/logo.png",
          accountDropdown: accountDropdownProps,
        }}
        navProps={{ itemsObjects: navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright © 2019
              <a href="."> myCityZ'</a>.
              All rights reserved.
            </React.Fragment>
          ),
        }}
      >
        {this.props.children}
      </Site.Wrapper>
    );
  }
}
export default (SiteWrapper);
