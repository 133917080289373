// @flow

import * as React from "react";
import {useEffect} from "react";

import {Card, colors, Grid, Page, StampCard,} from "tabler-react";

import C3Chart from "react-c3js";

import SiteWrapper from "./SiteWrapper.react";
import {connect} from "react-redux";
import {countUsers} from "./redux/actions/users";
import {countCommerceTypes} from "./redux/actions/commerceTypes";
import {countAlerts} from "./redux/actions/alerts";

function Home(props) {

    useEffect(() => {
        props.countAlerts();
        props.countCommerceTypes();
        props.countUsers();

    }, []);

    const {Auth} = props;
    return (
        <SiteWrapper user={Auth.user}>
            <Page.Content title="">
                <Grid.Row cards={true}>
                    <Grid.Col sm={12} lg={4}>
                        <StampCard
                            color="blue"
                            icon="users"
                            header={props.usersCount && props.usersCount.count}
                            footer={"Utilisateurs"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} lg={4}>
                        <StampCard
                            color="red"
                            icon="bell"
                            header={props.alertsCount.total}
                            footer={"Alertes"}
                        />
                    </Grid.Col>
                    <Grid.Col sm={12} lg={4}>
                        <StampCard
                            color="yellow"
                            icon="target"
                            header={props.commerceTypesCount}
                            footer={"Types de commerce"}
                        />
                    </Grid.Col>
                </Grid.Row>
                <Grid.Row cards={true}>
                        {props.alertsCount && <Grid.Col sm={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Alerte de Danger vs De securite</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <C3Chart
                                        style={{height: "12rem"}}
                                        data={{
                                            columns: [
                                                // each columns data
                                                ["data1", props.alertsCount.secured || 0],
                                                ["data2", props.alertsCount.danger || 0],
                                            ],
                                            type: "donut", // default type of chart
                                            colors: {
                                                data1: colors["green"],
                                                data2: colors["red"],
                                            },
                                            names: {
                                                // name of each serie
                                                data1: "Securite",
                                                data2: "Danger",
                                            },
                                        }}
                                        legend={{
                                            show: false, //hide legend
                                        }}
                                        padding={{
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    />
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                        }
                        <Grid.Col sm={6}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Nombre d'utilisateurs par types de commerce</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <C3Chart
                                        style={{height: "12rem"}}
                                        data={{
                                            columns: Object.keys(props.usersCount).map(i=>i!=='count' && [props.usersCount[i].name, props.usersCount[i].count]) || [],
                                            type: "pie", // default type of chart
                                        }}title
                                        legend={{
                                            show: false, //hide legend
                                        }}
                                        padding={{
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    />
                                </Card.Body>
                            </Card>
                        </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    );
}

const mapStateToProps = (state) => {
    return {
        Auth: state.Auth,
        alertsCount: state.Alerts.count,
        usersCount: state.Users.count,
        commerceTypesCount: state.CommerceTypes.count
    }
};
export default connect(mapStateToProps, {
    countUsers, countCommerceTypes, countAlerts
})(Home);
