import {COUNT, DELETE, LIST, POST, PUT, REQUEST} from "../../ActionType";
import {COMMERCE_TYPES} from "../../constants";

export const getCommerceTypes = (params,onSuccess) => {
    return {
        type: REQUEST(LIST(COMMERCE_TYPES)),
        params,
        onSuccess,
        loading:true,
    };
};

export const deleteCommerceType = (id,onSuccess) => {
    return {
        type: REQUEST(DELETE(COMMERCE_TYPES)),
        id,
        onSuccess,
        loading: true,
    };
};

export const postCommerceType = (commerceType,onSuccess) => {
    return {
        type: REQUEST(POST(COMMERCE_TYPES)),
        commerceType,
        onSuccess,
        loading: true,
    };
};

export const putCommerceType = (commerceType,onSuccess) => {
    return {
        type: REQUEST(PUT(COMMERCE_TYPES)),
        commerceType,
        onSuccess,
        loading: true,
    };
};

export const countCommerceTypes = (onSuccess) => {
    return {
        type: REQUEST(COUNT(COMMERCE_TYPES)),
        onSuccess,
        loading: true,
    };
};

