// @flow

import * as React from "react";

import { Error404Page } from "tabler-react";

type Props = {||};

function Error404(props: Props): React.Node {
  return <Error404Page subtitle='Oops.. Page non trouvée'
                       details='Nous sommes désolés, mais la page que vous avez demandée est introuvable.'
                       action='Retour'
  />;
}

export default Error404;
