import * as React from "react";
import {Fragment, useEffect, useState} from "react";
import MUIDataTable, {TableBodyCell, TableBodyRow} from "mui-datatables";
import {Button, Grid, Loader, Page,} from "tabler-react";
import {format, parseISO} from 'date-fns'
import {store} from 'react-notifications-component';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import SiteWrapper from "../../SiteWrapper.react";
import {deleteAlert, getAlert, getAlerts} from "../../redux/actions/alerts";
import swal from "@sweetalert/with-react";
import {
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from 'classnames';
import GoogleMap from "../../GoogleMap";


function Alerts(props) {
    const columns = [
        {
            name: "id",
            label: "",
            options: {
                filter: true,
                sort: false,
                display: 'excluded'
            }
        },
        {
            name: "user",
            label: "Utilisateur",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, row)=> <Link to={`/users/${row.rowData[1].id}`}>{value.name}</Link>
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value)=>format(parseISO(value),"dd/MM/yyyy HH:mm")
            }
        },
        {
            name: "message",
            label: "Message",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "location",
            label: "Location",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value) => {
                    return value ? <a href={'https://www.google.com/maps?q=' + value} rel="noopener noreferrer" target='_blank'>Voir
                        Localisation</a> : 'Aucune geolocalisation n\'est enregistree'
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, row) => {
                    return <div style={{minWidth:  '90px'}}>
                        <Button color="info" icon="eye" onClick={() => onDetails(row.rowData[0])}/>{' '}
                        <Button color="danger" icon="trash" onClick={() => onDelete(row.rowData[0])}/>
                    </div>
                }
            }
        }
    ];

    const options = {
        filterType: 'textfield',
        serverSide: true,
        filter: false,
        download: false,
        print: false,
        selectableRows: 'none',
        rowsPerPageOptions: [5, 10, 15, 20, 50, 100],
        customRowRender: (row, dataIndex, rowIndex) => (
            <TableBodyRow
                className={row[3].includes("Je suis en sécurité") ? "bg-green-lightest" : "bg-red-lightest"}
                options={options}
                data-testid={'MUIDataTableBodyRow-' + dataIndex}
                id={'MUIDataTableBodyRow-' + dataIndex}>
                {row.map(
                    (column, columnIndex) => (
                        columns[columnIndex].options.display !== 'excluded' && (
                            <TableBodyCell
                                {...(columns[columnIndex].setCellProps
                                    ? columns[columnIndex].setCellProps(column, dataIndex, columnIndex)
                                    : {})}
                                data-testid={`MuiDataTableBodyCell-${columnIndex}-${rowIndex}`}
                                dataIndex={dataIndex}
                                rowIndex={rowIndex}
                                colIndex={columnIndex}
                                columnHeader={columns[columnIndex].label}
                                print={columns[columnIndex].print}
                                options={options}
                                key={columnIndex}>
                                {column}
                            </TableBodyCell>
                        ))
                )}
            </TableBodyRow>
        )
    };

    useEffect(() => {
        props.getAlerts({limit: 10, ascending: 1, page: 1, byColumn: 0});
        const channel = window.Echo.channel('admins');
        channel.listen('.alert', function ({alert}) {
            props.getAlerts({limit: 10, ascending: 1, page: 1, byColumn: 0});
            store.addNotification({
                title: "Nouveau Alerte",
                message: alert.message,
                type: alert.message.includes("Je suis en sécurité") ? "success" : "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                width: 300,
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        });
    }, []);

    const [attachmentModal, setAttachmentModal] = useState(false);

    const [params, setParams] = useState({limit: 10, ascending: 1, page: 1, byColumn: 0});

    const [activeTab, setActiveTab] = useState('1');

    const [audio, setAudio] = useState({});
    const [video, setVideo] = useState({});
    const [image, setImage] = useState({});
    const [loading, setLoading] = useState(true);


    const onDetails = (id) => {
        setLoading(true);
        setAttachmentModal(true);
        props.getAlert(id, (alert) => {
            setLoading(false);
            setAudio((alert.attachments && alert.attachments.find(e => e.type === 'audio')) || {});
            setImage((alert.attachments && alert.attachments.find(e => e.type === 'image')) || {});
            setVideo((alert.attachments && alert.attachments.find(e => e.type === 'video')) || {});
        })

    };

    const onDelete = (id) => {
        swal({
            type: 'error',
            title: 'Etes vous sure de vouloir supprimer cette alerte?',
            icon: "warning",
            buttons: ["Annuler", 'Supprimer'],
            dangerMode: true,
        }).then((value) => {
            if (value)
                props.deleteAlert(id, () => {
                    swal({
                        title: "Supprimee!",
                        icon: "success",
                    });
                    props.getAlerts(params);
                });
        })

    };

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };


    const {Auth} = props;

    const onTableChange = (action, tableState) => {
        let newParams = params;
        switch (action) {
            case 'search':
                newParams = {...params, page: 1, query: tableState.searchText};
                break;
            case 'changePage':
                newParams = {...params, page: tableState.page + 1};
                break;
            case 'changeRowsPerPage':
                newParams = {...params, page: 1, limit: tableState.rowsPerPage};
                break;
            default:
                break;
        }
        setParams(newParams);
        if (params !== newParams)
            props.getAlerts(newParams);
    };

    return  (
        <SiteWrapper user={Auth.user}>
            <Page.Content>
                <Grid.Row cards={true}>
                    <MUIDataTable
                        className='w-100'
                        title="Alertes"
                        data={props.alerts}
                        columns={columns}
                        options={{
                            ...options,
                            count: props.count,
                            page: params.page - 1,
                            searchText: params.query,
                            rowsPerPage: params.limit,
                            onTableChange
                        }}
                    />
                </Grid.Row>
                <Modal style={{height: '700px'}} isOpen={attachmentModal} size='lg'
                       toggle={() => setAttachmentModal(!attachmentModal)}>
                    <ModalHeader toggle={() => setAttachmentModal(!attachmentModal)} charCode="">Pieces jointes de
                        l'alerte</ModalHeader>
                    <ModalBody style={{height: '500px'}}>
                        {loading ? <div style={{marginTop: 230}}
                                        className="d-flex justify-content-center align-content-center align-items-center">
                                <Loader/>
                            </div> :
                            <Fragment>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => {
                                                toggle('1');
                                            }}
                                        >
                                            Images
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => {
                                                toggle('2');
                                            }}
                                        >
                                            Videos
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '3'})}
                                            onClick={() => {
                                                toggle('3');
                                            }}
                                        >
                                            Audios
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '4'})}
                                            onClick={() => {
                                                toggle('4');
                                            }}
                                        >
                                            Geo-localisation
                                        </NavLink>
                                    </NavItem>

                                </Nav>
                                <TabContent activeTab={activeTab} className='m-2'>
                                    <TabPane tabId="1">
                                        <Row>
                                            {image.file ?
                                                <Col sm="12" className='w-100 my-3' style={{maxHeight: '400px'}}>
                                                    <img src={'data:image/png;base64,' + image.file} height='380px'
                                                         alt="attachment"/>
                                                </Col> : "Aucune image n'as ete attache avec cette alerte"}
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            {video.file ? <Col sm="12" style={{maxHeight: '400px'}}>
                                                <video controls className='w-100 my-3' height='380px'>
                                                    <source type="video/mp4"
                                                            src={"data:video/mp4;base64," + video.file}/>
                                                </video>
                                            </Col> : "Aucune video n'as ete attache avec cette alerte"}
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            {audio.file ? <Col sm="12">
                                                <audio controls className='w-100 my-3'>
                                                    <source src={'data:audio/wav;base64,' + audio.file}
                                                            type="audio/ogg"/>
                                                </audio>
                                            </Col> : "Aucun audio n'as ete attache avec cette alerte"}
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <Row>
                                            {props.alert.location ? <Col sm="12">
                                                <GoogleMap location={props.alert.location}/>
                                            </Col> : "Aucune localisation n'est attache avec cette alerte"}
                                        </Row>
                                    </TabPane>
                                </TabContent>

                            </Fragment>
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => {
                            setAttachmentModal(!attachmentModal);
                            setVideo({});
                            setImage({});
                            setAudio({});
                        }}>Fermer</Button>
                    </ModalFooter>
                </Modal>
            </Page.Content>
        </SiteWrapper>
    );
}

const mapStateToProps = (state) => {
    return {
        Auth: state.Auth,
        alerts: state.Alerts.list,
        alert: state.Alerts.alert,
        count: state.Alerts.count
    }
};
export default connect(mapStateToProps, {getAlerts, getAlert, deleteAlert})(Alerts);
