import {call, put, takeLatest} from 'redux-saga/effects';
import {getUser, login} from '../../../services/auth';

import {GET_USER, LOGIN_USER} from '../../constants';
import {FAILURE, REQUEST, SUCCESS} from '../../ActionType';

export function* index(payload) {
    try {
        const response = yield call(login, payload.user);
        yield put({ type: SUCCESS(LOGIN_USER), response });
        yield account(response, payload.onSuccess);
    } catch(error) {
        yield put({ type: FAILURE(LOGIN_USER), error });
        if(payload.onError)payload.onError(error.response)

    }
}



export function* account(payload, onSuccess) {
    try {
        const response = yield call(getUser, payload);
        yield put({type: SUCCESS(GET_USER), response});
        if(onSuccess)onSuccess();
    } catch(error) {
        yield put({ type: FAILURE(GET_USER), error });
    }
}

export function* watchUserAuthentication() {
    yield takeLatest(REQUEST(LOGIN_USER), index);
    yield takeLatest(REQUEST(GET_USER), account);
}
